SmsConfirmationCtrl.$inject = ["$timeout", "smsConfirmationService", "toaster", "$translate", "$sce", "$parse", "$scope", "$attrs", "$q"];
/* @ngInject */
function SmsConfirmationCtrl($timeout, smsConfirmationService, toaster, $translate, $sce, $parse, $scope, $attrs, $q) {
  var ctrl = this;
  ctrl.$onInit = function () {
    ctrl.smsCodeConfirmed = null;
    ctrl.smsCodeEnabled = true;
    ctrl.enableRetrySendSmsCode = true;
    ctrl.pageName = $parse($attrs.smsConfirmationPageName)($scope);
    ctrl.phone = $parse($attrs.smsConfirmationPhone)($scope);
    ctrl.smsConfirmationOnCheck = $parse($attrs.smsConfirmationOnCheck);
    ctrl.checkPhoneConfirmed();
    ctrl.getCaptchaSettings(ctrl.pageName);
  };
  ctrl.sendSmsCode = function (phone) {
    if (phone == null || phone === '' || phone.length <= 0) return;
    ctrl.smsCodeConfirmed = null;
    ctrl.smsCodeEnabled = true;
    ctrl.smsCode = '';
    smsConfirmationService.sendSmsCode(phone).then(function (data) {
      if (data.result === true) {
        ctrl.confirmationCode = data.code;
        toaster.pop('info', '', $translate.instant('Js.ConfirmSms.CodeSended') + phone);
        $timeout(function () {
          ctrl.enableRetrySend = true;
        }, 20 * 1000);
      }
    });
  };
  ctrl.confirmSmsCode = async function (value) {
    if (value == null || value.length < 4) return;
    if (ctrl.showCaptcha) {
      const captchaValid = await ctrl.checkCaptcha();
      if (captchaValid.result !== true) {
        if (captchaValid.message != null) {
          toaster.pop('error', '', captchaValid.message);
        }
        return;
      }
    }
    smsConfirmationService.confirmSmsCode(value).then(function (data) {
      if (data.result) {
        ctrl.smsCodeConfirmed = true;
        ctrl.smsCodeEnabled = false;
        ctrl.showCaptcha = false;
        smsConfirmationService.isPhoneChanged = false;
      } else {
        ctrl.reloadCaptcha();
        toaster.pop('error', '', $translate.instant('Js.ConfirmSms.WrongCode'));
        if (data.wrongConfirmation > 2 && !ctrl.showCaptcha) {
          ctrl.getCaptchaSettings(ctrl.pageName);
        }
      }
      ctrl.smsCode = '';
    });
  };
  ctrl.checkCaptcha = function () {
    if (typeof CaptchaSource != 'undefined') {
      CaptchaSource.InputId = 'CaptchaCode';
      var captchaCode = CaptchaSource.GetInputElement().value;
      if (captchaCode == null || captchaCode.length == 0) {
        return $q.resolve({
          result: false
        });
      }
      return smsConfirmationService.checkCaptcha(captchaCode).then(result => {
        if (result.data === true) {
          return $q.resolve({
            result: true
          });
        } else {
          ctrl.reloadCaptcha();
          return $q.resolve({
            message: $translate.instant('Js.Captcha.Wrong')
          });
        }
      });
    } else {
      return this.$q.resolve();
    }
  };
  ctrl.reloadCaptcha = function () {
    if (ctrl.showCaptcha && typeof CaptchaSource != 'undefined') {
      $timeout(function () {
        CaptchaSource.ReloadImage();
      }, 1000);
      CaptchaSource.GetInputElement().value = '';
    }
  };
  ctrl.getCaptchaSettings = function (pageName) {
    smsConfirmationService.getCaptchaSettings(pageName).then(function (data) {
      if (data.result) {
        ctrl.showCaptcha = data.obj;
        if (ctrl.showCaptcha) {
          ctrl.initCaptcha();
        }
      }
    });
  };
  ctrl.checkPhoneConfirmed = function () {
    smsConfirmationService.checkPhoneConfirmed().then(function (data) {
      var isPhoneConfirmed = data.obj.isPhoneConfirmed;
      var phone = data.obj.phone;
      if (phone != null && ctrl.smsConfirmationOnCheck != null) {
        ctrl.smsConfirmationOnCheck($scope, {
          phone
        });
      }
      if (isPhoneConfirmed) {
        ctrl.smsCodeConfirmed = true;
        ctrl.smsCodeEnabled = false;
      }
    });
  };
  ctrl.initCaptcha = function () {
    smsConfirmationService.getCaptchaHtml("smsConfirmation.captchaCode", ctrl.showCaptcha).then(function (result) {
      ctrl.captchaHtml = $sce.trustAsHtml(result);
    });
  };
  ctrl.getsmsCodeConfirmed = function () {
    return ctrl.smsCodeConfirmed && !smsConfirmationService.isPhoneChanged;
  };
}
;
export default SmsConfirmationCtrl;