SmsAuthCtrl.$inject = ["$window", "$timeout", "$translate", "toaster", "$q", "smsConfirmationService"];
/* @ngInject */
function SmsAuthCtrl($window, $timeout, $translate, toaster, $q, smsConfirmationService) {
  var ctrl = this;
  const waitingSeconds = 10;
  ctrl.$onInit = function () {
    ctrl.returnToFirstStep();
    ctrl.settings = {};
    ctrl.getCaptchaSettings();
  };
  ctrl.returnToFirstStep = function () {
    ctrl.currentForm = 'enterPhoneForm';
    ctrl.firstSend = false;
    ctrl.enableRetrySend = true;
    ctrl.smsCodeConfirmed = false;
    ctrl.phone = '';
    ctrl.smsCode = '';
    ctrl.smsCodeEnabled = true;
    ctrl.smsCodeModel = [];
    ctrl.phoneText = '';
  };
  ctrl.validate = function (noCheckSmsCode) {
    if (!noCheckSmsCode && ctrl.currentForm === 'confirmSmsForm') {
      if (ctrl.smsCode.length < 4) {
        return false;
      }
    }
    if (ctrl.currentForm === 'enterPhoneForm' && !ctrl.firstSend) {
      if (ctrl.phone === undefined || ctrl.phone === null || ctrl.phone === '' || ctrl.phone.length <= 0) {
        toaster.pop('error', '', 'Укажите корректный номер телефона');
        return false;
      }
    }
    return true;
  };
  ctrl.sendSmsCode = function () {
    if (!ctrl.validate(true)) return;
    var phoneBlock = document.getElementById('smsConfirmationPhone');
    ctrl.phoneText = phoneBlock !== null ? phoneBlock.value : '';
    smsConfirmationService.sendSmsCode(ctrl.phone).then(function (data) {
      if (data.result != true) {
        if (data.errors != null && data.errors.length > 0) {
          toaster.pop('error', null, data.errors[0]);
        } else {
          toaster.pop('error', null, $translate.instant('Js.ConfirmSms.ErrorSendSms'));
        }
      } else {
        ctrl.confirmationCode = data.code;
        if (!ctrl.firstSend) {
          ctrl.firstSend = true;
          ctrl.currentForm = "confirmSmsForm";
        }
        toaster.pop('info', '', $translate.instant('Js.ConfirmSms.CodeSended') + ctrl.phone);
        ctrl.setCountdown();
      }
    });
  };
  ctrl.confirmSmsCode = async function () {
    if (!ctrl.validate(false)) return;
    if (ctrl.showCaptcha) {
      const captchaValid = await ctrl.checkCaptcha();
      if (captchaValid.result !== true) {
        if (captchaValid.message != null) {
          toaster.pop('error', '', captchaValid.message);
        }
        return;
      }
    }
    smsConfirmationService.confirmSmsCode(ctrl.smsCode).then(function (data) {
      if (data.result) {
        ctrl.smsCodeConfirmed = true;
        ctrl.enter();
      } else {
        ctrl.reloadCaptcha();
        toaster.pop('error', '', $translate.instant('Js.ConfirmSms.WrongCode'));
        if (data.wrongConfirmation > 2) {
          ctrl.getCaptchaSettings();
        }
      }
    });
  };
  ctrl.enter = function () {
    if (ctrl.smsCodeConfirmed) {
      ctrl.checkCaptcha().then(function () {
        smsConfirmationService.authByPhone(ctrl.phone).then(function (data) {
          if (data.result) {
            var redirect = data.obj.redirectTo;
            if (redirect != null) {
              $window.location = redirect;
            } else {
              $window.location.reload();
            }
          } else {
            if (data.result.error != null && data.result.error.length > 0) {
              toaster.pop('error', data.result.error);
            }
          }
        });
      }).catch(result => {
        return;
      });
    }
  };
  ctrl.getCaptchaSettings = function () {
    smsConfirmationService.getCaptchaSettings().then(function (data) {
      if (data.result) {
        ctrl.showCaptcha = data.obj;
        smsConfirmationService.showCaptcha = data.obj;
      }
    });
  };
  ctrl.checkCaptcha = function () {
    if (ctrl.showCaptcha && typeof CaptchaSource != 'undefined') {
      CaptchaSource.InputId = 'CaptchaCode';
      var captchaCode = CaptchaSource.GetInputElement().value;
      return smsConfirmationService.checkCaptcha(captchaCode).then(result => {
        if (result.data === true) {
          return $q.resolve({
            result: true
          });
        } else {
          ctrl.reloadCaptcha();
          return $q.resolve({
            message: $translate.instant('Js.Captcha.Wrong')
          });
        }
      });
    } else {
      return $q.resolve();
    }
  };
  ctrl.reloadCaptcha = function () {
    if (ctrl.showCaptcha && typeof CaptchaSource != 'undefined') {
      $timeout(function () {
        CaptchaSource.ReloadImage();
      }, 1000);
      CaptchaSource.GetInputElement().value = '';
    }
  };
  ctrl.setCountdown = function () {
    ctrl.enableRetrySend = false;
    ctrl.countdownSeconds = waitingSeconds;
    if (ctrl.pageType === 'login') {
      var counter = setInterval(function () {
        ctrl.countdownSeconds--;
        var timerBlock = document.getElementById("smsConfirmationCountdownTimer");
        if (timerBlock !== null) {
          timerBlock.innerHTML = 'Отправить код повторно можно через ' + ctrl.countdownSeconds + ' сек.';
        }
        if (ctrl.countdownSeconds <= 0) {
          if (timerBlock !== null) {
            timerBlock.innerHTML = '';
          }
          ctrl.enableRetrySend = true;
          clearInterval(counter);
          var form = document.querySelector('.sms-confirmation-modal');
          if (form !== null) {
            form.click();
          }
        }
      }, 1000);
    } else {
      $timeout(function () {
        ctrl.enableRetrySend = true;
      }, ctrl.countdownSeconds * 1000);
    }
  };
  ctrl.setFocus = function (fromId, toId) {
    $timeout(function () {
      var from = document.getElementById(fromId);
      if (from !== null && from.value !== ' ' && from.value.length > 0) {
        var to = document.getElementById(toId);
        if (to !== null) {
          to.focus();
          to.selectionStart = 0;
        }
      }
    }, 100);
  };
}
;
export default SmsAuthCtrl;