import smsConfirmationInputTemplate from "../templates/smsConfirmationInput.html";
function smsConfirmationDirective() {
  return {
    restrict: 'A',
    scope: true,
    controller: 'SmsConfirmationCtrl',
    controllerAs: 'smsConfirmation',
    bindToController: true
  };
}
const smsConfirmationInputComponent = {
  controller: 'SmsConfirmationInputCtrl',
  templateUrl: smsConfirmationInputTemplate,
  bindings: {
    count: '<',
    disabled: '<?',
    onComplete: '&'
  }
};
export { smsConfirmationDirective, smsConfirmationInputComponent };