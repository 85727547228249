authSmsService.$inject = ["$http", "$q"];
/* @ngInject */
function authSmsService($http, $q) {
  var service = this;
  service.showCaptcha = false;
  service.isPhoneChanged = false;
  service.sendSmsCode = function (phone) {
    return $http.post('user/sendSmsCode', {
      phone: phone,
      rnd: Math.random()
    }).then(function (response) {
      return response.data;
    });
  };
  service.confirmSmsCode = function (code) {
    return $http.post('user/confirmSmsCode', {
      code: code,
      rnd: Math.random()
    }).then(function (response) {
      return response.data;
    });
  };
  service.authByPhone = function (phone) {
    return $http.post('user/authByPhone', {
      phone: phone,
      rnd: Math.random()
    }).then(function (response) {
      return response.data;
    });
  };
  service.getCaptchaSettings = function (pageName) {
    return $http.post('user/getSmsCaptchaSettings', {
      pageName: pageName
    }).then(function (response) {
      return response.data;
    });
  };
  service.checkCaptcha = function () {
    let captchaCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    if (!service.showCaptcha) {
      return $q.when({
        data: true
      });
    }
    return $http.get(CaptchaSource.ValidationUrl + '&i=' + captchaCode).then(function (result) {
      return result;
    });
  };
  service.checkPhoneConfirmed = function () {
    return $http.get('user/GetSmsPhoneConfirmed').then(function (result) {
      return result.data;
    });
  };
  service.onPhoneChanged = function () {
    service.isPhoneChanged = true;
  };
  service.getCaptchaHtml = function (ngModel, showCaptcha) {
    service.showCaptcha = showCaptcha;
    return $http.post('/commonExt/getCaptchaHtml', {
      ngModel: ngModel
    }).then(function (response) {
      return response.data;
    });
  };
}
;
export default authSmsService;